import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMembersSection from "../sections/teamMembers"
import OurLocation from "../sections/ourLocation"
import ContactForm from "../sections/contactForm"
import AboutUs from "../components/aboutUs"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About us" />
    <article>
      <header id="about-us" className="about-us">
        <div className="container py-5">
          <div className="col">
            <div className="row justify-content-center">
              <h1 className="dubbelbit">About us</h1>
            </div>
          </div>
          <div className="col">
            <div className="row justify-content-center lead">
              <AboutUs />
            </div>
          </div>
        </div>
      </header>

      <TeamMembersSection />

      <ContactForm />

      <OurLocation />
    </article>
  </Layout>
)

export default AboutUsPage
